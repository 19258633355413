<template>
  <div v-if="$store.state.dealer.cash" class="dealer-container">
    <v-toolbar flat>
      <v-toolbar-title>
        Dealer
      </v-toolbar-title>
      <v-divider class="mx-2" inset vertical></v-divider>
      <v-btn color="primary" dark @click="initialize" class="mb-2">Sync</v-btn>
      <v-divider class="mx-2" inset vertical></v-divider>
    </v-toolbar>
    <v-toolbar flat>
      <v-toolbar-title>
        Cash
        <span
          :class="
            `${$store.state.dealer.cash < 0 ? 'red--text' : 'green--text'}`
          "
          >{{ $store.state.dealer.cash | formatMoney }}</span
        >
      </v-toolbar-title>
    </v-toolbar>
    <v-row>
      <v-col cols="2"  v-for="(item, index) in $store.state.dealer.all.cash" :key="index">
        <v-data-table
          :headers="headers"
          :items="item.data"
          :items-per-page="50"
          hide-default-footer
          class="elevation-1"
        >
          <template v-slot:header="{ props: { headers } }">
            <thead>
              <tr>
                <th :colspan="headers.length">
                  This is a header
                </th>
              </tr>
            </thead>
          </template>
          <template v-slot:top>
            <v-toolbar flat>
              <v-toolbar-title
                >{{ item.name }} <br /><span
                  :class="
                    `${
                      item.data.reduce(
                        (a, b) => a + b.value,
                        0
                      ) < 0
                        ? 'red--text'
                        : 'green--text'
                    }`
                  "
                  >{{
                    item.data.reduce(
                      (a, b) => a + b.value,
                      0
                    ) | formatMoney
                  }}</span
                ></v-toolbar-title
              >
            </v-toolbar>
          </template>
          <template v-slot:item.value="{ item }">
            <div>{{ item.value | formatMoney }}</div>
          </template>
          <template v-slot:item.name="{ item }">
            <v-icon small class="mr-2" @click="editItem(item)">edit</v-icon>
            <span>{{ item.name }}</span>
          </template>
        </v-data-table>
      </v-col>

    </v-row>
    <v-toolbar flat>
      <v-toolbar-title>
        Jackpot
        <span
          :class="
            `${$store.state.dealer.jackPot < 0 ? 'red--text' : 'green--text'}`
          "
          >{{ $store.state.dealer.jackPot | formatMoney }}</span
        >
      </v-toolbar-title>
    </v-toolbar>
    <v-row>
      <v-col cols="2"  v-for="(item, index) in $store.state.dealer.all.jackPot" :key="index">
        <v-data-table
          :headers="headers"
          :items="item.data"
          :items-per-page="50"
          hide-default-footer
          class="elevation-1"
        >
          <template v-slot:header="{ props: { headers } }">
            <thead>
              <tr>
                <th :colspan="headers.length">
                  This is a header
                </th>
              </tr>
            </thead>
          </template>
          <template v-slot:top>
            <v-toolbar flat>
              <v-toolbar-title
                >{{ item.name }} <br /><span
                  :class="
                    `${
                      item.data.reduce(
                        (a, b) => a + b.value,
                        0
                      ) < 0
                        ? 'red--text'
                        : 'green--text'
                    }`
                  "
                  >{{
                    item.data.reduce(
                      (a, b) => a + b.value,
                      0
                    ) | formatMoney
                  }}</span
                ></v-toolbar-title
              >
            </v-toolbar>
          </template>
          <template v-slot:item.value="{ item }">
            <div>{{ item.value | formatMoney }}</div>
          </template>
          <template v-slot:item.name="{ item }">
            <v-icon small class="mr-2" @click="editItem(item)">edit</v-icon>
            <span>{{ item.name }}</span>
          </template>
        </v-data-table>
      </v-col>

    </v-row>
    <v-row justify="center">
      <v-dialog v-model="dialog" max-width="600px">
        <v-card>
          <v-card-title>
            <span class="headline">Dealer</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    v-model="current.id"
                    label="id"
                    disabled
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    v-model="current.value"
                    label="value"
                    placeholder="value"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="dialog = false"
              >Close</v-btn
            >
            <v-btn color="blue darken-1" text @click="save">Save</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>
<script>
export default {
  data() {
    return {
      current: {},
      dialog: false,
      headers: [
        {
          text: "name",
          align: "left",
          sortable: false,
          value: "name",
        },
        { text: "value", value: "value" },
        // { text: "Actions", value: "action", sortable: false },
      ],
    };
  },
  methods: {
    async save() {
      await this.$store.dispatch("dealer/update", this.current);
      this.dialog = false;
      this.$store.dispatch("dealer/getAll");
    },
    async editItem(item) {
      this.current = item;
      this.dialog = true;
    },
    async initialize() {
      await this.$store.dispatch("dealer/getAll");
    },
  },
  async mounted() {
    this.initialize();
  },
};
</script>
<style>
.dealer-container td,
.dealer-container th {
  padding: 0 0 0 2px !important;
}
.dealer-container .col-2 {
  padding: 10px 0 10px 12px !important;
  max-width: 14%;
}
</style>
